import type { AppData } from '../../viewer.app';
import model from './model';

const selectors = {
  commentsSlot: '#slotComments',
};

export default model.createController(({ $w, appData: _appData }) => {
  const appData = _appData as AppData;

  return {
    pageReady: async () => {
      await appData.subjects.postPageRenderModel.subscribe(
        (data) => ($w(selectors.commentsSlot).slot.resourceId = data.post.id),
      );
    },
    exports: {},
  };
});
