import type { HttpClient } from '@wix/http-client';
import {
  getPostPage,
  getPostPagePreview,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import {
  createAction,
  getCategoryIds,
  type RequestClient,
  isExperimentEnabled,
  EXPERIMENTS,
  fetchTagsSuccess,
} from '@wix/communities-blog-client-common';
import type { AggregatorRequest } from '../../post-list-widget/types';
import { getLanguageCode } from '../selectors/locale-selectors';
import { getDemoPosts } from '../services/demo-posts';
import getEnvironment from '../services/get-environment';
import { normalizePost, normalizePostV3 } from '../services/post-utils';
import type { AppState, GetState, NormalizedPost } from '../types';
import type { FlowAPI, WixCodeApi } from '../types/platform-types';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

interface FetchPostRequestAction {
  type: typeof FETCH_POST_REQUEST;
  payload: { postSlug: string };
}

interface FetchPostSuccessAction {
  type: typeof FETCH_POST_SUCCESS;
  payload: { post: NormalizedPost; postSlug: string };
}

interface FetchPostFailureAction {
  type: typeof FETCH_POST_FAILURE;
  payload: { postSlug: string; error: unknown };
}

interface PreviewPostParams {
  includeDraft?: boolean;
  instance?: string;
}

interface MakeRequestParams {
  includeDraft?: boolean;
  instance?: string;
  httpClient: HttpClient;
  languageCode: string;
  dispatch: Function;
  getState: GetState;
  wixCodeApi: WixCodeApi;
  flowAPI: FlowAPI;
  loadRichContent: boolean;
}

export type PostActionTypes =
  | FetchPostRequestAction
  | FetchPostSuccessAction
  | FetchPostFailureAction;

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequestToPlatformizedApi = async (
  postSlugOrId: string,
  {
    includeDraft,
    instance,
    httpClient,
    languageCode,
    getState,
    dispatch,
    wixCodeApi,
    flowAPI,
    loadRichContent,
  }: MakeRequestParams,
) => {
  const platformizedParams = {
    translationsName: 'main',
    languageCode,
    loadRichContent,
  };

  const request = includeDraft
    ? getPostPagePreview({
        draftPostId: postSlugOrId,
        ...platformizedParams,
      })
    : getPostPage({
        postId: postSlugOrId,
        ...platformizedParams,
      });

  try {
    const getInitialData = () =>
      httpClient.request(
        request,
        instance
          ? {
              signedInstance: instance,
            }
          : undefined,
      );

    let initialData;
    const warmupDataKey = 'OldPostPageWarmupData';

    const warmupDataGet = () => {
      try {
        const value = JSON.parse(
          wixCodeApi.window.warmupData.get(warmupDataKey),
        );
        return value;
      } catch {
        return undefined;
      }
    };

    if (
      flowAPI.essentials.experiments.enabled(
        EXPERIMENTS.USE_WARMUP_STATE_IN_OLD_POST_PAGE,
      ) &&
      flowAPI.environment.isSSR
    ) {
      initialData = await getInitialData();
      wixCodeApi.window.warmupData.set(
        warmupDataKey,
        JSON.stringify(initialData),
      );
    } else {
      initialData = warmupDataGet() ?? (await getInitialData());
    }

    const postPage = initialData?.data?.postPage;
    const post = postPage?.post;

    if (!post || !postPage || typeof post.slug === 'undefined') {
      throw new Error('Post not found');
    }

    if (
      isExperimentEnabled(getState(), EXPERIMENTS.USE_POST_PAGE_ADAPTER_DATA)
    ) {
      dispatch(fetchTagsSuccess(postPage.tags));
    }

    return {
      ...normalizePostV3(post),
      tags: postPage.tags ?? [],
      /*
        To maintain compatibility with the old post page, we need to return the slug in the slugs array.
        It is used in the post page to determine if the post is avialable by slug or not.
      */
      slugs: [post.slug, postSlugOrId],
    };
  } catch (error: any) {
    if (error?.response?.status) {
      wixCodeApi.seo.setSeoStatusCode(error.response.status);
    } else {
      wixCodeApi.seo.setSeoStatusCode(500);
    }

    throw error;
  }
};

export const fetchPost = (
  postSlug: string,
  { includeDraft, instance }: PreviewPostParams = {},
) => {
  return (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
      flowAPI,
    }: {
      request: RequestClient;
      httpClient: HttpClient;
      wixCodeApi: WixCodeApi;
      aggregatorRequest: AggregatorRequest;
      flowAPI: FlowAPI;
    },
  ) => {
    dispatch(fetchPostRequest({ postSlug }));
    const state = getState();

    const isRichContentEnabled = isExperimentEnabled(
      state,
      EXPERIMENTS.LOAD_RICH_CONTENT_OOI,
    );

    const promise = makeRequestToPlatformizedApi(postSlug, {
      httpClient,
      languageCode: getLanguageCode(state),
      includeDraft,
      instance,
      dispatch,
      getState,
      wixCodeApi,
      flowAPI,
      loadRichContent: isRichContentEnabled,
    });

    return completeFetchPost(postSlug, promise)(dispatch, getState, {
      httpClient,
      wixCodeApi,
      aggregatorRequest,
    });
  };
};

export const preFetchPost =
  (postSlug: string, { includeDraft, instance }: PreviewPostParams = {}) =>
  (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      httpClient,
      wixCodeApi,
      flowAPI,
    }: { httpClient: HttpClient; wixCodeApi: WixCodeApi; flowAPI: FlowAPI },
  ) => {
    dispatch(fetchPostRequest({ postSlug }));

    const state = getState();
    const languageCode = getLanguageCode(state);
    const isRichContentEnabled = isExperimentEnabled(
      state,
      EXPERIMENTS.LOAD_RICH_CONTENT_OOI,
    );

    return makeRequestToPlatformizedApi(postSlug, {
      languageCode,
      includeDraft,
      instance,
      httpClient,
      dispatch,
      getState,
      wixCodeApi,
      flowAPI,
      loadRichContent: isRichContentEnabled,
    });
  };

export const completeFetchPost =
  (postSlug: string, preFetchResult: Promise<NormalizedPost>) =>
  async (
    dispatch: (action: PostActionTypes) => void,
    getState: () => AppState,
    {
      wixCodeApi,
      httpClient,
      aggregatorRequest,
    }: {
      wixCodeApi: WixCodeApi;
      httpClient: HttpClient;
      aggregatorRequest: AggregatorRequest;
    },
  ) => {
    try {
      let post;
      let capturedError;

      try {
        post = await preFetchResult;
      } catch (e) {
        capturedError =
          e && typeof e === 'object' && 'response' in e ? e.response : e;

        if (
          capturedError &&
          typeof capturedError === 'object' &&
          'status' in capturedError &&
          capturedError.status === 404 &&
          getEnvironment(wixCodeApi).isEditorSegment
        ) {
          const resp = await getDemoPosts({
            httpClient,
            getState,
            dispatch,
            wixCodeApi,
            aggregatorRequest,
            query: { slugs: [postSlug] },
          });
          post = resp?.posts?.[0];
          if (post) {
            capturedError = null;
          }
        }
      }

      if (capturedError) {
        throw capturedError;
      }

      const normalizedPost = normalizePost({
        state: getState(),
        post,
        blogCategoryIds: getCategoryIds(getState()),
      });

      dispatch(fetchPostSuccess({ post: normalizedPost, postSlug }));

      return normalizedPost;
    } catch (error) {
      dispatch(fetchPostFailure({ postSlug, error }));
      throw error;
    }
  };
